import React, { Component } from 'react';
import styled from 'styled-components';

// context
import { NavContext } from '../../context/NavContext';

// atoms
import BarsIcon from './icons/BarsIcon';

// styled components
const StyledNavToggleBtn = styled('button')`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const StyledBarsIcon = styled(BarsIcon)`
  width: ${({ theme: { iconSizes } }) => iconSizes.medium};
  margin-right: 10px;
  
  .primary-path {
    fill: ${({ theme: { colors } }) => colors.lightest};
  }
  .secondary-path {
    fill: ${({ theme: { colors } }) => colors.lightest};
  }
`;


class NavToggleBtn extends Component {
    static contextType = NavContext;


    handlePress = () =>
        this.context.toggleNavHidden();


    render() {
        return (
            <StyledNavToggleBtn onClick={this.handlePress}>
                <StyledBarsIcon/>
            </StyledNavToggleBtn>
        );
    }
}

export default NavToggleBtn;