import { darken, transparentize } from 'polished';


const colors = {
    primary: '#6CDAE7', // turquoise-blue
    secondary: '#45A29E', // keppel
    tertiary: '#d0d6de', // mischka

    dark: null,
    darker: '#222428', // ebony clay
    darkest: '#0B0C10', // woodsmoke

    light: '#C5C6C7', // silver-sand
    lighter: '#EEF0F3', // off-white
    lightest: '#fff'
};

const mutedColors = {
    light: darken(0.1, colors.light)
};

const opaqueColors = {
    primary: transparentize(0.05, colors.primary)
};


// smaller: 0.7,
// small: 0.8,
// medium: 1,
// large: 1.5,
// larger: 2.2,
// largest: 3.8

const rawFontSizes = {
    smaller: 11,
    small: 13,
    medium: 16,
    large: 24,
    larger: 35,
    largest: 60
};

const FONT_SIZE_UNIT = 'px';

export default {
    navWidth: 230,
    headerHeight: 50,
    itemWidth: 208,

    colors,
    mutedColors,
    opaqueColors,

    rawFontSizes,
    fontSizes: Object.keys(rawFontSizes).reduce((fontSizes, key) => (
        { ...fontSizes, [key]: rawFontSizes[key] + FONT_SIZE_UNIT }
    ), {}),
    // fontSizes: {
    //     root: '16px',
    //
    //     ...fontSizes
    // },

    fontWeights: {
        // lighter: 100,
        light: 300,
        normal: 400,
        bold: 500,
        bolder: 700
    },

    fonts: {
        quote: "'DM Serif Display', serif"
    },

    iconSizes: {
        small: '14px',
        medium: '20px',
        large: '30px'
    },

    avatarSizes: {
        small: '36px'
    },

    shadows: {
        dark: `2px 2px 1px ${transparentize(0.9, colors.darker)}`,
        light: `2px 2px 5px ${colors.light}`
    },

    // breakpoints: {
    //     phone: 540,
    //     tablet_portrait: 540,
    //     tablet_landscape: 768,
    //     desktop: 1030,
    //     big_desktop: 1350
    // }
};


// import React from 'react';
// import { ThemeProvider } from 'styled-components';
//
//
// const theme = {
//     colors: {
//         primary: '#6CDAE7',
//         secondary: '#45A29E',
//
//
//     },
//
//     fontSizes: {
//
//     },
//
//     shadow: '0 0 5px red'
// };
//
// const Theme = ({ children }) => (
//     <ThemeProvider theme={theme}>{ children }</ThemeProvider>
// );
//
// export default Theme;