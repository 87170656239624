import React from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

// styled components
const StyledNavMenu = styled('ul')`
  list-style-type: none;
  padding-left: 0;
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  display: table;
  
  margin: 10px 10px 10px auto;
  padding: 0 5px;

  cursor: pointer;
  border-radius: 5px;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large}!important;
  text-decoration: none;
  
  color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.light : colors.lightest
  }!important;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  
  &:before {
    opacity: 0;
    
    content: '';
    position: absolute;
    
    width: 100%;
    height: 46%;
    left: 0;
    bottom: 0;
    z-index: -1;
    
    background: ${({ theme: { colors } }) => colors.primary};
  }
  
  
  &:hover:before, &.active:before {
    opacity: 1;
  }
`;

const NavSpacer = styled('li')`
  height: 15px;
`;


function NavMenu() {
    return (
        <StyledNavMenu id="navItems">
            <StyledNavLink to="/" exact>HOME</StyledNavLink>
            <StyledNavLink to="/explore" disabled={true}>EXPLORE</StyledNavLink>
            <StyledNavLink to="/halloffame" disabled={true}>HALL OF FAME</StyledNavLink>
            <StyledNavLink to="/trending" disabled={true}>TRENDING</StyledNavLink>

            <NavSpacer/>

            <StyledNavLink to="/music" disabled={true}>MUSIC</StyledNavLink>
            <StyledNavLink to="/talent" disabled={true}>TALENT</StyledNavLink>

            <NavSpacer/>

            {/*<NavLink to="/festivals" className="disabled">FESTIVALS</NavLink>*/}
            <StyledNavLink to='/lambofest'>LAMBOFEST</StyledNavLink>
            <StyledNavLink to="/opportunities">OPPORTUNITIES</StyledNavLink>
            <StyledNavLink to='/business'>BUSINESS</StyledNavLink>
        </StyledNavMenu>
    )
}

export default NavMenu;