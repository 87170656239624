import React, { Component } from "react";
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';

// context
import { NavContext } from '../../context/NavContext';

// atoms
import NavToggleBtn from '../atoms/NavToggleBtn';
import Brand from '../atoms/Brand';

// molecules
import NavMenu from "../molecules/NavMenu";

// styled components
const StyledNav = styled('nav')`
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: ${({ isHidden, width }) => isHidden ? 0 : width}px;
  visibility: ${({ isHidden }) => isHidden ? 'hidden' : 'visible'};
  opacity: ${({ isHidden }) => isHidden ? 0 : 1};
  
  //display: ${({ isHidden }) => isHidden ? 'none' : 'flex'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  //padding: 10px 0;
  padding-bottom: 10px;
  
  text-align: right;
  background-color: ${({ theme: { colors } }) => colors.darkest};
  box-shadow: ${({ theme: { shadows } }) => shadows.dark};
  
  transition: width 0.1s ease-in, opacity linear 0.1s${({ isHidden }) => isHidden && ', visibility 0s linear 0.1s'};
  // transition: width 0.1s ease-in-out, visibility 0s linear 0.1s;
`;

const NavHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ theme: { navBreakpointExceeded } }) =>
    navBreakpointExceeded ? 'space-between' : 'center'
  };
  
  margin: 0 20px;
  height: ${({ theme: { headerHeight } }) => headerHeight}px;
`;

const NavFooter = styled('div')`
  padding: 0 10px;

  font-weight: ${({ theme: { fontWeights } }) => fontWeights.normal};
  text-align: center;
  color: ${({ theme: { colors } }) => colors.light};
  
  ul {
    list-style-type: none;
    
    padding-left: 0;
    margin-bottom: 10px;
    
    a {
      display: inline-block;
      
      margin: 0 5px;
      
      color: ${({ theme: { colors } }) => colors.light};
      
      &:hover {
        color: ${({ theme: { mutedColors } }) => mutedColors.light} 
      }
    }
  }
`;

const NavBackdrop = styled('div')`
  position: fixed;
  z-index: 998;
  
  width: 100%;
  height: 100%;
  
  background: ${({ theme: { colors } }) => transparentize(0.5, colors.darker)};
`;


class Nav extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        isHidden: PropTypes.bool.isRequired
    };

    static contextType = NavContext;


    handleClickBackdrop = () =>
        this.context.toggleNavHidden();

    render() {
        const { theme: { navBreakpointExceeded, navIsHidden }, ...navProps } = this.props;

        return (
            <>
                <StyledNav {...navProps}>
                    <NavHeader>
                        { navBreakpointExceeded && <NavToggleBtn/> }
                        <Brand/>
                        { navBreakpointExceeded && <div/> }
                    </NavHeader>

                    <NavMenu/>

                    <NavFooter>
                        <ul>
                            <Link to="/legal/terms"><small>Terms of Service</small></Link>
                            <Link to="/legal/privacy"><small>Privacy Policy</small></Link>
                            {/*<Link to="/community"><small>Community Guidelines</small></Link>*/}
                        </ul>

                        <small>&copy; {new Date().getFullYear()} ReelShare</small>
                    </NavFooter>

                </StyledNav>

                {
                    (navBreakpointExceeded && !navIsHidden) && (
                        <NavBackdrop onClick={this.handleClickBackdrop}/>
                    )
                }
            </>
        );
    }
}


export default withTheme(Nav);