
import axios, { CancelToken } from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://api.reelshare.uk/'
    : 'https://dev-api.reelshare.uk/';

const source = CancelToken.source();

const api = (bearerToken) => {
    let headers = {};
    if (bearerToken) headers.Authorization = 'Bearer '+bearerToken;

    return axios.create({
        baseURL: API_BASE_URL,
        headers,
        cancelToken: source.token
    });
};

export {
    api,
    API_BASE_URL,
    source
}