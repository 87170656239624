
/*------Modules------*/
// import Cookies from 'react-cookies';

/*------Libs------*/
import { api, API_BASE_URL } from './api';


class Session {
    constructor(prevSessionId, path, timeoutDelay, App) {
        this._app = App;

        this._id = null; // id is null until session started
        this._user = null; // user is null until session authed/started with user
        this._pathActions = {}; // empty object, assigned value by 'resetPathActions'
        this._resetPathActions(path); // assign path actions default values

        this._starting = true; // state is starting until start() has completed
        this._startAttemptsRemaining = 3; // start will retry 3 times before giving up

        this._timeoutDelay = timeoutDelay; // delay before idleTime begins incrementing
        this._idleTimeout = null; // timeout to begin incrementing paths idleTime
        this._idleCounter = null; // interval to increment paths idleTime

        this._start(prevSessionId); // start session with optional previous session id
        this._initActivityListeners(); // begin checking for idleness

        this._startEvs = []; // functions to be called on session start
        // this._onStartEvType = 'sessionStart_'+((new Date()).getTime());
        // this._onStartEv = new CustomEvent(this._onStartEvType);

        if (navigator.sendBeacon){
            // Beacon API available, store session data on unload
            window.addEventListener('unload', this._sendBeacon.bind(this));
        }

        this._app.props.history.listen((location, _) => {
            // listen for path changes, to save actions on path change
            if (this._pathActions.path !== location.pathname) {
                this._updateCurrentPath(location.pathname);
            }
        });
    }

    _start(prevSessionId) {
        // create new session in db
        console.log('session starting...');

        let reqBody = {
            entryPath: this._pathActions.path
        };

        if (prevSessionId) reqBody.prevSessionId = prevSessionId;

        api(null).post('/session', {
            ...reqBody

        }).then(response => {
            // returns created sessions id and optional user
            this.id = response.data.id;
            this.user = response.data.user || null;

            console.log('started session with id: ' + this.id);
            this._starting = false;
            this._onActivity(); // instantly trigger idle timeout
            while (this._startEvs.length) {
                this._startEvs.shift().call();
            }

        }).catch(err => {
            this._starting = false;
            console.error(err.response);

            if (--this._startAttemptsRemaining > 0) {
                this._start(prevSessionId);
            }
        });
    }

    _initActivityListeners() {
        // used to detect inactivity
        window.addEventListener('load', this._onActivity.bind(this));
        window.addEventListener('scroll', this._onActivity.bind(this), true); // true so it bubbles from scrollable elements
        window.addEventListener('mousemove', this._onActivity.bind(this));
        window.addEventListener('mousedown', this._onActivity.bind(this)); // touchscreen presses
        window.addEventListener('touchstart', this._onActivity.bind(this)); // touchscreen swipes
        window.addEventListener('click', this._onActivity.bind(this)); // touchpad clicks
        window.addEventListener('keypress', this._onActivity.bind(this));
    }

    _onActivity() {
        // not idle
        clearTimeout(this._idleTimeout);
        clearInterval(this._idleCounter);

        this._idleTimeout =
            setTimeout(() => {
                console.log('user is idle...');
                this._pathActions.idleTime += this._timeoutDelay;

                this._idleCounter = setInterval(() => {
                    this._pathActions.idleTime += 1;
                }, 1000);

            }, this._timeoutDelay*1000);
    }

    _updateCurrentPath(newPath) {
        console.log('saving path actions & updating current path to: ' + newPath);

        let pathActions = this._resetPathActions(newPath);
        console.log('session path updated to: ' + newPath);

        let patch = {
            op: 'push',
            path: 'actions',
            value: pathActions
        };

        let apiPath = '/session/'+this._id;
        api(null).patch(apiPath, {
            patches: [patch]
        }).then(response => {
            console.log('successfully saved path actions');

        }).catch(err => {
            console.error('failed to save path actions: ' + err);
        })
    }

    // resets path actions to default values and returns previous path actions
    _resetPathActions(newPath) {
        let pathActions = this._pathActions;
        this._pathActions = {
            createdAt: Math.floor((new Date()).getTime() / 1000),
            path: newPath,
            idleTime: 0
        };
        return pathActions;
    }

    _sendBeacon() {
        console.log('sending beacon...');

        let beaconUrl = API_BASE_URL+'session/beacon/'+this._id;
        let pathActions = this._pathActions;
        navigator.sendBeacon(
            beaconUrl,
            JSON.stringify({
                patches: [
                    {op:'push', path:'actions', value:pathActions}
                ]
            })
        );
    }

    setPathAction(action, value) {
        this._pathActions[action] = value;
    }

    async auth(username, password) {
        if (this._id) {


            // VALIDATE USERNAME/PASS HEREEEE?? or shouldn't it be done with login form no??


            console.log('authing session...');

            let apiPath = '/session/auth/'+this._id;
            // let userData, authErr;
            try {
                let response = await api(null).post(apiPath, {
                    username,
                    password
                });
                console.log('RESPONSE: ' + response.data);
                this.user = response.data;

            } catch(err) {
                console.error('ERROR: ' + err);
                throw new Error(err.response.status.toString());
                // switch (err.response.status) {
                //     case 401: throw new Error('incorrect password');
                //     case 403: throw new Error('user not verified');
                //     case 404: throw new Error('user not found');
                //     default: throw new Error();
                // }
            }

            // this._app.props.history.push('/p/'+this._user.username);
            window.location.href = '/p/'+this._user.username;
        }
    }

    unAuth = () => {
        console.log('unAuthing session...');
        // Cookies.remove('session', {path:'/'});
        this._app.cookies.remove('session', {path:'/'});
        this._app.cookies.remove('user', {path:'/'});
        // document.cookie = 'session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        window.location.href = '/';
    };


    // mutator methods
    set id(id) {
        this._id = id;

        // Cookies.save('session', id, {
        //     path: '/',
        //     maxAge: (60 * 60 * 24 * 30) // 30 days
        // })
        this._app.cookies.set('session', id, {
            path: '/',
            maxAge: (60 * 60 * 24 * 30) // 30 days
        })
    }

    set user(user) {
        // update this.user
        this._user = user;

        if (user) {
            // store user data to cookie for quick access
            let encodedUserData = btoa(JSON.stringify(user));
            this._app.cookies.set('user', encodedUserData, {
                path: '/',
                maxAge: (60 * 60 * 24 * 30) // 30 days
            });
        }

        this._app.forceUpdate();
    }

    set onStart(startEv) {
        if (this._starting) {
            // will be called on session start
            this._startEvs.push(startEv);
        } else {
            // session already started, so just call the func
            startEv();
        }
    }


    // accessor methods
    get id() {
        return this._id;
    }

    get user() {
        return this._user;
    }

    get pathActions() {
        return this._pathActions;
    }

    get starting() {
        return this._starting;
    }
}

export default Session;