import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// assets
import logo_dark from '../../images/logo_dark.png';
import logo_light from '../../images/logo_light.png';

// styled components
const StyledBrand = styled('div')`
  text-align: center;
  
  img {
    height: 30px;
    margin: 0 auto;
  }
`;


class Brand extends Component {
    static propTypes = {
        dark: PropTypes.bool
    };


    render () {
        const { dark } = this.props;

        return (
            <StyledBrand>
                <Link to="/">
                    <img src={dark ? logo_dark : logo_light} alt="brand" />
                </Link>
            </StyledBrand>
        )
    }
}

export default Brand;