import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router';
// import Cookies from 'react-cookies'; // Cookies.load(), Cookies.save(), Cookies.remove()
import { compose } from 'recompose';
import { withCookies, Cookies } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';

// libraries
import Session from './libs/session';

// context
import { SessionContext } from './context/SessionContext';
import { NavContext } from './context/NavContext';

// styles
import './scss/app.scss';
import theme from './styles/theme';

// organisms
import Nav from './components/organisms/Nav';
// import Header from './components/organisms/Header';

// pages
const Home = React.lazy(() => import('./components/pages/Home'));
const Opportunities = React.lazy(() => import('./components/pages/Opportunities'));
const Film = React.lazy(() => import('./components/pages/Film'));
const Dance = React.lazy(() => import('./components/pages/Dance'));
const Business = React.lazy(() => import('./components/pages/Business'));
const BlankTestPage = React.lazy(() => import('./components/pages/BlankTestPage'));
// const LamboFestInfo = React.lazy(() => import('./components/pages/LamboFestInfo'));
const LamboFest = React.lazy(() => import('./components/pages/LamboFest'));
// reel pages
const Upload = React.lazy(() => import('./components/pages/Upload'));
const Watch = React.lazy(() => import('./components/pages/Watch'));
// auth pages
const Register = React.lazy(() => import('./components/pages/auth/Register'));
const Verify = React.lazy(() => import('./components/pages/auth/Verify'));
// user pages
const Profile = React.lazy(() => import('./components/pages/user/Profile'));
const EditProfile = React.lazy(() => import('./components/pages/user/EditProfile'));
// legal pages
const Terms = React.lazy(() => import('./components/pages/legal/Terms'));
const Privacy = React.lazy(() => import('./components/pages/legal/Privacy'));
// error pages
const NotFound = React.lazy(() => import('./components/pages/NotFound'));

// constants
const NAV_HIDE_BREAKPOINT = 1030;

// styled components
const StyledApp = styled('div')`

  h1, .h1 {
    font-size: ${({ theme: { rawFontSizes, itemsPerRow } }) => 
        (itemsPerRow >= 4) ? rawFontSizes.largest 
            : (itemsPerRow >= 2) ? rawFontSizes.largest * 0.9 
            : rawFontSizes.largest * 0.7 
    }px;
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  }
  
  h2, .h2 {
    font-size: ${({ theme: { rawFontSizes, itemsPerRow } }) => 
        (itemsPerRow >= 4) ? rawFontSizes.larger
            : (itemsPerRow >=2) ? rawFontSizes.larger * 0.9
            : rawFontSizes.larger * 0.7
    }px;
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold}; 
  }
  
  h3, .h3 {
    font-size: ${({ theme: { rawFontSizes, itemsPerRow } }) =>
        (itemsPerRow >= 4) ? rawFontSizes.large
            : (itemsPerRow >=2) ? rawFontSizes.large * 0.9
            : rawFontSizes.large * 0.6
    }px;
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.bolder};
  }
  
  p, .p {
    font-size: ${({ theme: { rawFontSizes, itemsPerRow } }) =>
        (itemsPerRow >= 4) ? rawFontSizes.medium
            : (itemsPerRow >= 2) ? rawFontSizes.medium * 0.9
            : rawFontSizes.medium * 0.9
    }px;
    //font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
  }
  
  small, .small {
    font-size: ${theme.fontSizes.small};
  }
  
  strong, .strong {
    font-weight: ${theme.fontWeights.bolder};
  }
  
  a, .a {
    font-size: ${theme.fontSizes.medium};
    text-decoration: none;
    color: ${theme.colors.darker};
  }
`;

const StyledMain = styled('main')`
  color: ${theme.colors.darkest};
  
  margin-left: ${({ navIsHidden }) => navIsHidden ? 0 : theme.navWidth}px;
  margin-left: ${({ navIsHidden, theme: { navBreakpointExceeded } }) =>
    (navIsHidden || navBreakpointExceeded) ? 0 : theme.navWidth
  };
  
  transition: margin-left 0.1s ease-in;
  
  & > div {
    padding-top: ${theme.headerHeight + 10}px;
  }
`;




// const PrivateRoute = ({ component:Component, ...rest }) => (
//     <Route {...rest} render={(props) => {
//         console.log(this.session);
//
//
//         return (
//             <Component {...props} />
//         )
//     }}/>
// );


class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);


        this.state = {
            windowWidth: 0,
            windowHeight: 0,
            navIsHidden: true
        };


        this.cookies = props.cookies;

        // const { cookies } = props;

        // let prevSessionId = Cookies.load('session');
        let prevSessionId = this.cookies.get('session');
        console.log('prevSessionId: ' + prevSessionId);

        // let sessionTimeout = 60*10; // 10 mins
        let sessionTimeout = 60; // 1 min
        // let sessionTimeout = 10; // 10 secs (for testing)
        this.session = new Session(prevSessionId, props.location.pathname, sessionTimeout, this);

        this._unlistenUrlChanges = null;
    }

    componentDidMount() {
        // https://support.mouseflow.com/support/solutions/articles/44001535025-tracking-url-changes-with-react
        this._unlistenUrlChanges = this.props.history.listen((location, action) => {
            this.session.currentPath = location.pathname;
        });

        this._updateWindowDimensions();
        window.addEventListener('resize', this._updateWindowDimensions);

        this._initGlobalActivityTrackers();
    }

    componentWillUnmount() {
        this._unlistenUrlChanges && this._unlistenUrlChanges();

        window.removeEventListener('resize', this._updateWindowDimensions);
    }


    _updateWindowDimensions = () => {
        const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
        const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

        this.setState({
            windowWidth, windowHeight,
            navIsHidden: (windowWidth < NAV_HIDE_BREAKPOINT)
        });
    };


    _initGlobalActivityTrackers() {
        // max scrolled
        if (window.hasOwnProperty('pageYOffset')){ // if browser supports (> IE8)
            window.addEventListener('scroll', (ev)=>{
                if (window.pageYOffset > (this.session.pathActions['maxScrolled'] || 0)) {
                    this.session.setPathAction('maxScrolled', window.pageYOffset);
                }
            });
        }
    }


    AuthedOnlyRoute = ({ component:Component, ...rest }) => {
        let userFromCookies = this.cookies.get('user');
        if (userFromCookies) {
            userFromCookies = JSON.parse(atob(userFromCookies));
        }

        return (
            <Route {...rest} render={(props) => (
                userFromCookies
                    ? <Component {...props} />
                    : <Redirect to='/auth/login' />
            )}/>
        );
    };

    UnAuthedOnlyRoute = ({ component:Component, ...rest }) => {
        let userFromCookies = this.cookies.get('user');
        if (userFromCookies) {
            userFromCookies = JSON.parse(atob(userFromCookies));
        }

        return (
            <Route {...rest} render={(props) => (
                !userFromCookies
                    ? <Component {...props}/>
                    : <Redirect to={'/p/'+userFromCookies.username} />
            )}/>
        )
    };

    toggleNavHidden = () =>
        this.setState((prevState) =>
            ({ navIsHidden: !prevState.navIsHidden })
        );


    render() {
        const { navIsHidden, windowWidth } = this.state;

        const navBreakpointExceeded = (windowWidth < NAV_HIDE_BREAKPOINT);
        const itemsPerRow = windowWidth ? (() => {
            let { itemWidth, navWidth } = theme;
            itemWidth += 10;

            let itemsPerRow = (navIsHidden || navBreakpointExceeded)
                ? Math.floor((windowWidth-10) / itemWidth)
                : Math.floor((windowWidth-navWidth-10) / itemWidth);

            return (itemsPerRow < 5) ? itemsPerRow : 5;
        })() : 1;
        // console.log(itemsPerRow);

        const appTheme = {
            ...theme,

            navIsHidden,
            windowWidth,
            navBreakpointExceeded,
            itemsPerRow
        };

        const AuthedOnlyRoute = this.AuthedOnlyRoute;
        const UnAuthedOnlyRoute = this.UnAuthedOnlyRoute;

        const navApi = {
            toggleNavHidden: this.toggleNavHidden
        };

        return (
            <SessionContext.Provider value={ this.session }>
                <NavContext.Provider value={navApi}>
                    <ThemeProvider theme={appTheme}>
                        <StyledApp>
                            <Nav width={theme.navWidth} isHidden={navIsHidden} />

                            <StyledMain navIsHidden={navIsHidden}>
                                <React.Suspense fallback={<span>Loading...</span>}>
                                    <Switch>
                                        <Route exact path='/' component={Home} />

                                        <UnAuthedOnlyRoute exact path='/auth/register' component={Register} />
                                        <UnAuthedOnlyRoute exact path='/auth/verify/:verifyCode?' component={Verify} />

                                        <Route exact path='/p/:reqUser' component={Profile} />
                                        <AuthedOnlyRoute exact path='/upload' component={Upload} />
                                        <AuthedOnlyRoute exact path='/account/edit' component={EditProfile} />

                                        <Route exact path='/opportunities/' component={Opportunities} />

                                        <Route exact path='/business' component={Business} />
                                        <Route exact path='/film' component={Film} />
                                        <Route exact path='/dance' component={Dance} />
                                        <Route exact path='/test' component={BlankTestPage} />
                                        <Route exact path='/lambofest' component={LamboFest} />
                                        {/*<Route exact path='/pepsi' component={LamboFest} />*/}

                                        <Route exact path='/r/:reelId' component={Watch} />

                                        <Route exact path='/legal/terms' component={Terms} />
                                        <Route exact path='/legal/privacy' component={Privacy} />

                                        <Route component={NotFound} />
                                    </Switch>
                                </React.Suspense>
                            </StyledMain>

                        </StyledApp>
                    </ThemeProvider>
                </NavContext.Provider>
            </SessionContext.Provider>
        );
    }
}

// export default App;
// export default withRouter(App)
export default compose(
    withRouter,
    withCookies
)(App)